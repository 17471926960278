.block.listing.amministrazioneTrasparenteTablesTemplate
  .persone-amministrazione-trasparente {
  table {
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
    th.text-uppercase {
      word-break: normal;
    }
  }
  @media screen and (max-width: 992px) {
    table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
    table tr {
      display: block;
      &:nth-child(2n) {
        background-color: rgba(221, 221, 221, 0.5);
      }
    }

    table td {
      border-bottom: 1px solid #ddd;
      display: block;
      font-size: 0.8em;
      &:is([data-label='titolo'], [data-label='nominativo']) {
        font-size: 0.975em;
      }

      &:empty {
        display: none;
      }
    }

    table td::before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
      width: 100%;
      text-align: left;
    }

    table td:is([data-label='titolo'], [data-label='nominativo']):before {
      display: none;
    }

    table td:last-child {
      border-bottom: 0;
    }
  }
}
