.public-ui {
  @import 'custom/layout/header';
  @import 'custom/layout/footer';
  @import 'custom/blocks/atTablesOverrides';
  @import 'custom/blocks/UOlist';
  @import 'custom/views/bandoView';
  @import 'custom/views/dettagli_procedimenti_view';
  @import 'custom/views/pageHeaderExtend';
  @import 'custom/views/trasparenza_view';

  a.btn-tertiary {
    color: #000 !important;
  }

  .btn-tertiary svg {
    fill: #000;
  }
  /* FIX PROBLEM WITH VOLTO-GDPR */
  .gdpr-privacy-settings .settings-column.technical .toggle-wrapper div label {
    margin-bottom: 0px;
  }
  .blockquote,
  blockquote {
    border-left-color: $tertiary;
  }
}

// .public-ui .it-footer .it-footer-main .it-brand-wrapper a .icon {
//   width: auto;
// }

.pdr-1 {
  padding-right: 1rem;
}

/* FIX CUSTOMER SATISFACTION PANEL BUG */
.section-customer-satisfaction-panel #main > .content-area > main > p {
  display: none;
}
