//site
$primary-h: 348 !default;
$primary-s: 60.24 !default;
$primary-b: 65 !default;

// $secondary-h:;
// $secondary-h: 350 !default;
// $secondary-s: 51 !default;
// $secondary-b: 35 !default;

$primary: #592d37;
$secondary: #a54255;
$tertiary: #1d8a9e;
//$tertiary-text: $white;

//$header-bg-color: $monet-blue;
$header-center-bg-color: $secondary;
$external-link-fill-buttons: #fff;
$external-link-fill-subfooter: #fff;
//$headings-color: $primary;
//$semibold: 600;
