.it-header-wrapper {
  .external-link {
    fill: $external-link-fill-buttons !important;
  }
  .it-header-slim-wrapper {
    background: $primary !important;
  }
  .it-header-navbar-wrapper {
    background-color: $secondary;
  }
  .it-header-center-wrapper {
    background-color: $secondary;
    @media (max-width: 992px) {
      height: 100%;
      min-height: 80px;
    }
  }
}
.header-logo {
  @media (max-width: 992px) {
    img {
      width: 140px !important;
      height: 55.2px !important;
    }
  }
  margin-bottom: 0;
  display: flex;
  align-items: center;
  margin-right: 1rem;
  img {
    width: 175px;
    height: 69.18px;
    filter: blur(0);
    -webkit-filter: blur(0);
    -webkit-transform: translateZ(1px);
    transform: translateZ(1px);
    image-rendering: -moz-crisp-edges; /* Firefox */
    image-rendering: -o-crisp-edges; /* Opera */
    // image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
    image-rendering: crisp-edges;
  }
}
@media (max-width: 992px) {
  #it-navigation-collapse .it-brand-wrapper {
    background-color: $secondary;

    .it-brand-text h2.no_toc {
      color: #fff;
    }
  }
}
