.page-header-extend-expires {
  margin-top: 2rem;
  margin-bottom: 1rem;

  span {
    padding: 0.35rem 0.75rem;
    background-color: #808080;
    border-radius: 0.5rem;
    color: white;
    font-weight: 500;
  }
}
