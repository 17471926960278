.uo-list-table {
  border: 1px solid #858585;
  .font-serif {
    font-family: 'Titillium Web' !important;
  }

  th {
    white-space: nowrap;
  }

  th,
  td {
    padding: 10px 15px;
  }

  tr,
  thead tr {
    border-bottom: 1px solid #858585;
  }

  th,
  td {
    border-right: 1px solid #858585;
  }
}
