.trasparenza-reggio-index {
  .menu-wrapper {
    .link-list {
      a {
        padding: 9px 24px !important;
      }
    }
  }
  #trasparenza-page-content {
    .trasparenza-item-header {
      font-size: 1em;
      h4 {
        font-size: 1.333em;
        font-weight: 600;
      }
    }
    margin-top: 0;

    ul {
      padding: 0;
      &.trasparenza-first-level-wrapper {
        > li {
          &:first-child {
            margin-top: 0px;
          }
          margin-top: 32px;
        }
      }
    }
    .trasparenza-second-level-wrapper {
      .trasparenza-item {
        font-size: 18px;
      }
    }

    li {
      padding: 0;
      list-style: none;
      a {
        padding: 0px;
        margin-top: 12px;
        font-size: 18px;
        line-height: 1.555;
      }

      // span {
      //   font-size: 1.777rem;
      //   font-weight: 700;
      //   line-height: 1.25;
      // }
    }

    // .link-list-wrapper {
    //   margin-bottom: 2rem;
    // }

    .trasparenza-item.list-item {
      width: fit-content;
      padding-left: 0;
    }
  }
}
