.dettagli-procedimento {
  padding: 0;
  p {
    margin-bottom: 0.75rem;
    display: flex;
    p {
      margin: 0;
    }
  }
  p.dettagli-procedimenti-entry {
    margin-bottom: 0.75rem;
    display: flex;
    p {
      margin: 0;
    }
    .font-weight-semibold.pdr-1 {
      flex-basis: 35%;
      flex-grow: 0;
      flex-shrink: 0;
      font-weight: 600;

      &:after {
        content: ':';
      }
    }
    .richtext-blocks.text-serif {
      flex-basis: 65%;
      flex-shrink: 0;
    }
    @media (max-width: 767px) {
      flex-direction: column;
      .font-weight-semibold.pdr-1 {
        flex-basis: 100%;
        padding: 0;
        margin-bottom: 0.5rem;
        &:after {
          content: '';
          display: none;
        }
      }
      .richtext-blocks.text-serif {
        flex-basis: 100%;
      }
    }
  }

  .dettagli-procedimento-loader {
    width: 100%;
    height: 500px;
    flex-wrap: wrap;
    .progress-spinner {
      width: 92px;
      height: 92px;
      .progress-spinner-inner {
        width: 92px;
        height: 48px;
      }
    }
  }
  .procedimenti-cards-container {
    display: grid;
    width: 100%;
    gap: 1rem;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }
}
