.bando-view {
  #allegati {
    h5 {
      margin: 0.75rem 0 !important;
    }
    .card-body {
      height: 100%;
      align-items: center;
      display: flex;
    }
    .card-text a {
      overflow-wrap: anywhere;
    }
  }

  .bando_state .card-text {
    display: flex;
    align-items: center;

    .icon {
      margin-right: 0.5rem;
    }
  }

  .bandoActive {
    border-color: #008000;
  }

  .bandoInProgress {
    border-color: #0000ff;
  }

  .bandoClosed {
    border-color: #ff0000;
  }
}
